import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { addHeaderTagOnce, getCookie, removeHeaderTag } from "../global/Global";
import md5 from "md5";
import moment from "moment/moment";
import { LoginContext } from "./Login";
import InputImage from "./InputImage";
import { imageData } from "../global/FileData";
import DefaultAvatar_150 from "../default-avatar_150.png";
import DefaultCustomBack from "../default-customback.webp";
import RecentPosts from "./RecentPosts";
import Settings from "./Settings";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import Tooltip from "./Tooltip";
import NotFound from "./NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartCrack,
  faMars,
  faVenus,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import "./User.css";

const User = () => {
  const { user: myUser, reloadUserInfo } = useContext(LoginContext);
  const navigate = useNavigate();
  const myUserId = parseInt(getCookie("userid"));
  const [user, setUser] = useState();
  let { userid, username } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const ignoreRequest = useRef(false);
  const [settingsToken, setSettingsToken] = useState(0);

  useDidUpdateEffect(() => {
    if (!userid || userid == 0) return;

    logT("userpage", "CHANGED USERID", userid);
    ignoreRequest.current = false;
    fetchData();
  }, [userid]);

  useDidUpdateEffect(() => {
    if (!username || username.length == 0) return;

    logT("userpage", "CHANGED USERNAME", username);
    ignoreRequest.current = false;
    fetchData(true);
  }, [username]);

  const fetchData = async (ignoreUserid = false) => {
    if (ignoreRequest?.current) {
      logT("user", "ignore request (before)");
      return;
    }
    const token = getCookie("token") || "";
    window.loadingStart();
    const data = await window.TALKVIOAPI("user", {
      myuserid: (myUser && myUser.userid) || myUserId || 0,
      token,
      userid: ignoreUserid && username ? 0 : parseInt(userid) || 0,
      username,
    });
    if (ignoreRequest?.current) {
      logT("user", "ignore request (after)");
      window.loadingFinish();
      return;
    }
    if (!data.user) {
      setNotFound(true);
    }
    setUser(data.user);

    if (data.user?.username) {
      document.title = `${data.user?.username} - Talkvio`;
    }

    if (!username && userid) {
      logT("userpage", "repace page to username", data.user?.username);
      window.history.replaceState(
        null,
        document.title,
        `/${data.user?.username}`,
      );
    }

    if (data.user?.username) {
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute(
          "content",
          `user, homepage, blog, talk, reddit, pikabu, ${data.user?.username}`,
        );
    }

    if (data.user?.username) {
      const user = data.user;
      // og tags
      addHeaderTagOnce("og:title", data.user?.username, "property");
      addHeaderTagOnce("og:url", "https://talkvio.com", "property");
      addHeaderTagOnce("og:type", "article", "property");
      addHeaderTagOnce(
        "og:image",
        user.avatarrevision > 0
          ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
          : DefaultAvatar_150,
        "property",
      );
      addHeaderTagOnce(
        "og:description",
        data.user?.username + " " + __("profile") + " " + __("page"),
        "property",
      );

      // twitter tags
      addHeaderTagOnce("twitter:card", "summary_large_image", "name");
      addHeaderTagOnce("twitter:site", "@talkvio", "name");
      addHeaderTagOnce("twitter:title", data.user?.username, "name");
      addHeaderTagOnce(
        "twitter:url",
        `https://talkvio.com/${data.user?.username}`,
        "name",
      );
      addHeaderTagOnce("twitter:domain", "talkvio.com", "name");
      addHeaderTagOnce(
        "twitter:image",
        user.avatarrevision > 0
          ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
          : DefaultAvatar_150,
        "name",
      );
      addHeaderTagOnce(
        "twitter:description",
        data.user?.username + " " + __("profile") + " " + __("page"),
        "name",
      );

      addHeaderTagOnce(
        "canonical",
        `https://talkvio.com/${data.user?.username}`,
        "rel",
        "href",
        "link",
      );
    }

    window.loadingFinish();
  };

  useEffect(() => {
    ignoreRequest.current = false;
    fetchData();

    return () => {
      logT("post", "clean all headers and metags");
      removeHeaderTag("og:title", "property");
      removeHeaderTag("og:url", "property");
      removeHeaderTag("og:type", "property");
      removeHeaderTag("og:image", "property");
      removeHeaderTag("og:description", "property");

      removeHeaderTag("twitter:card", "name");
      removeHeaderTag("twitter:site", "name");
      removeHeaderTag("twitter:title", "name");
      removeHeaderTag("twitter:url", "name");
      removeHeaderTag("twitter:domain", "name");
      removeHeaderTag("twitter:image", "name");
      removeHeaderTag("twitter:description", "name");

      removeHeaderTag("canonical", "rel", "link");
    };
  }, []);

  const EditorInfo = () => {
    if (user?.usergroupid != 13 || user?.userid != myUser?.userid) return null;

    return (
      <div className="editorBlock">
        <div>
          <b>Редакторский блок</b>
        </div>
        {typeof user.moneyPrize != "undefined" ? (
          <div>
            Ваша сумма редактора: {user.moneyPosts} + {user.moneyComments} ={" "}
            {user.moneyPrize}
          </div>
        ) : null}
        {typeof user.moneyPostsCount != "undefined" ? (
          <div>Расчитанных постов: {user.moneyPostsCount}</div>
        ) : null}
        {typeof user.moneyCommentsCount != "undefined" ? (
          <div>Расчитанных комментариев: {user.moneyCommentsCount}</div>
        ) : null}
        {typeof user.moneyEditorPostsTariff != "undefined" &&
        user.moneyEditorPostsTariff > 0 ? (
          <div>Таргетный тариф за пост: {user.moneyEditorPostsTariff}</div>
        ) : null}
        {typeof user.moneyEditorCommentsTariff != "undefined" &&
        user.moneyEditorCommentsTariff > 0 ? (
          <div>
            Таргетный тариф за комментарий: {user.moneyEditorCommentsTariff}
          </div>
        ) : null}
        {typeof user.targetEditorsPosts != "undefined" &&
        user.targetEditorsPosts >= 0 ? (
          <div>
            Таргет на 24 часа на редакторские посты (без учета обычных):{" "}
            {user.targetEditorsPosts}
          </div>
        ) : null}
        {typeof user.targetEditorsComments != "undefined" &&
        user.targetEditorsComments >= 0 ? (
          <div>
            Таргет на 24 часа на редакторские комментарии (без учета обычных):{" "}
            {user.targetEditorsComments}
          </div>
        ) : null}
        {typeof user.editorPostsPeriodicLeft != "undefined" &&
        user.editorPostsFirstFree ? (
          <div>
            Осталось платных слотов для постов: {user.editorPostsPeriodicLeft}{" "}
            (следующий слот откроется через{" "}
            {(
              (user.editorPostsFirstFree +
                1 * 24 * 60 * 60 -
                Date.now() / 1000) /
              (60 * 60)
            ).toFixed(2)}{" "}
            ч){" "}
            {user.editorPostsPeriodicLeft === 0
              ? "[Вы можете продолжать писать в свое удовольствие не смотря на это! Спасибо!]"
              : ""}
          </div>
        ) : null}
        {typeof user.editorPostsPeriodicLeft != "undefined" &&
        user.editorCommentsFirstFree ? (
          <div>
            Осталось платных слотов для комментариев:{" "}
            {user.editorCommentsPeriodicLeft} (следующий слот откроется через{" "}
            {(
              (user.editorCommentsFirstFree +
                1 * 24 * 60 * 60 -
                Date.now() / 1000) /
              (60 * 60)
            ).toFixed(2)}{" "}
            ч){" "}
            {user.editorCommentsPeriodicLeft === 0
              ? "[Вы можете продолжать писать в свое удовольствие не смотря на это! Спасибо!]"
              : ""}
          </div>
        ) : null}
      </div>
    );
  };

  const UserNote = ({ user, myUser, onNoteChange }) => {
    const [showNoteInput, setShowNoteInput] = useState(false);
    const [userNote, setUserNote] = useState(user.mynote || "");
    const [noteChanged, setNoteChanged] = useState(false);

    useEffect(() => {
      setUserNote(user.mynote || "");
      setNoteChanged(false);
    }, [user.mynote]);

    const saveNote = async () => {
      const token = getCookie("token");
      const userid = parseInt(getCookie("userid"));
      const data = await window.TALKVIOAPI("usernote", {
        userid,
        token,
        targetuserid: user.userid,
        note: userNote,
      });
      window.displayError(data);
      if (data.success) {
        setNoteChanged(false);
        onNoteChange();
      }
    };

    if (!myUser?.userid || myUser.userid === user?.userid) return null;

    return (
      <div className="userNote">
        <div
          className={`userNote-button${user.mynote ? " has-note" : ""}`}
          onClick={() => setShowNoteInput(!showNoteInput)}
        >
          <FontAwesomeIcon icon={faNoteSticky} />
          <span>{user.mynote ? __("Edit note") : __("Personal note")}</span>
        </div>

        {showNoteInput && (
          <div className="userNote-editor">
            <textarea
              value={userNote}
              onChange={(e) => {
                setUserNote(e.target.value);
                setNoteChanged(true);
              }}
              onFocus={() => {
                window.blockKeyTag("posts", "user-note");
              }}
              onBlur={() => {
                window.unblockKeyTag("posts", "user-note");
              }}
              placeholder={__("Add a private note about this user")}
            />
            {noteChanged && (
              <button className="userNote-save" onClick={saveNote}>
                {__("Save note")}
              </button>
            )}
          </div>
        )}

        {!showNoteInput && user.mynote && (
          <div className="userNote-display">{user.mynote}</div>
        )}
      </div>
    );
  };

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className="userpage">
      {user ? (
        <div className="userheader">
          <div className="userinfo">
            <div className="customback">
              {myUser?.userid == user.userid ? (
                <InputImage
                  onFiles={async (files) => {
                    const token = getCookie("token");
                    const userid = parseInt(getCookie("userid"));
                    const data = await window.TALKVIOAPI("setSettings", {
                      userid,
                      token,
                      settings: {
                        customback: await imageData(files[0]),
                      },
                    });
                    window.displayError(data);
                    setSettingsToken(settingsToken + 1);
                    fetchData();
                  }}
                >
                  {user.customback
                    ? `https://talkvio.com/customback/customback_${user.userid}.webp?${settingsToken}`
                    : DefaultCustomBack}
                </InputImage>
              ) : (
                <img
                  src={
                    user.customback
                      ? `https://talkvio.com/customback/customback_${user.userid}.webp`
                      : DefaultCustomBack
                  }
                  alt={"Аватар пользователя " + user.username}
                />
              )}
            </div>
            <div className="avatar">
              {myUser?.userid == user.userid ? (
                <InputImage
                  onFiles={async (files) => {
                    const token = getCookie("token");
                    const userid = parseInt(getCookie("userid"));
                    const data = await window.TALKVIOAPI("setSettings", {
                      userid,
                      token,
                      settings: {
                        avatar: await imageData(files[0]),
                      },
                    });
                    window.displayError(data);
                    fetchData();
                  }}
                >
                  {user.avatarrevision > 0
                    ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                    : DefaultAvatar_150}
                </InputImage>
              ) : (
                <img
                  src={
                    user.avatarrevision > 0
                      ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                      : DefaultAvatar_150
                  }
                  alt={"Аватар пользователя " + user.username}
                />
              )}
            </div>
            <div className="username">
              {user.username}
              <sup className="gender">
                {user.gender == "male" ? (
                  <FontAwesomeIcon className="male" icon={faMars} />
                ) : null}
                {user.gender == "female" ? (
                  <FontAwesomeIcon className="female" icon={faVenus} />
                ) : null}
              </sup>
            </div>
            {user.usergroupid == 14 &&
            (myUser?.isAdmin ||
              myUser?.isModerator ||
              myUser?.userid == user.userid) ? (
              <div className="contester">Участник конкурса</div>
            ) : null}
            <div className="usertitle">{user.usertitle}</div>
            <div className="flex row center-row wrap">
              {myUser?.userid > 0 && user.userid != myUser?.userid ? (
                <div
                  className={
                    "clickButton subscriptionButton" +
                    (user.subscriptionid > 0 ? " unsubscribe" : " subscribe")
                  }
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    const token = getCookie("token");
                    const userid = parseInt(getCookie("userid"));
                    const data = await window.TALKVIOAPI("subscribe", {
                      userid,
                      token,
                      targetuserid: user.userid,
                    });
                    window.displayError(data);
                    if (data.success) {
                      fetchData();
                    }
                  }}
                >
                  {user.subscriptionid > 0
                    ? __("Unsubscribe")
                    : __("Subscribe")}
                </div>
              ) : null}
              {myUser?.userid > 0 && user.userid != myUser?.userid ? (
                <div
                  className={
                    "clickButton blockButton" +
                    (myUser.blockusers
                      ?.split(",")
                      .map((i) => i | 0)
                      .includes(user.userid)
                      ? " unblock"
                      : " block")
                  }
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    const token = getCookie("token");
                    const userid = parseInt(getCookie("userid"));
                    const data = await window.TALKVIOAPI("blockUser", {
                      userid,
                      token,
                      targetuserid: user.userid,
                    });
                    window.displayError(data);
                    if (data.success) {
                      reloadUserInfo();
                    }
                  }}
                >
                  {myUser.blockusers
                    ?.split(",")
                    .map((i) => i | 0)
                    .includes(user.userid)
                    ? __("Unblock")
                    : __("Block")}
                </div>
              ) : null}
              <UserNote user={user} myUser={myUser} onNoteChange={fetchData} />
            </div>
            <div className="registation">
              {__("Registered since")}{" "}
              {moment(user.joindate * 1000).format("MMMM Do YYYY")} (
              {moment(user.joindate * 1000).fromNow()})
            </div>
            <div className="lastactivity">
              {__("Last activity")}:{" "}
              {moment(user.lastactivity * 1000).fromNow()}
            </div>
            {myUser?.userid == user.userid ? (
              <div
                className="clickButton"
                onClick={() => setShowSettings(!showSettings)}
              >
                {__("Change profile settings")}
              </div>
            ) : null}
            <div className="split"></div>
            <div className="infoblock">
              <div className="info-element posts">
                <div className="value">{user.postsonly} </div>
                <div className="desc">{__("Posts")}</div>
              </div>
              <div className="info-element posts">
                <div className="value">{user.comments} </div>
                <div className="desc">{__("Comments")}</div>
              </div>
              <div className="info-element reputation">
                <div className="value">{user.reputation} </div>
                <div className="desc">{__("Reputation")}</div>
              </div>
              <div className="info-element karma">
                <div className="value">
                  {myUser && myUser.userid > 0 ? (
                    <Tooltip
                      top={true}
                      hint={__(
                        "Change your attitude to this user to positive. This will increase user karma, you need 5 karma to perform this action.",
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faHeart}
                        className={
                          "karmaButton up" +
                          (user?.mykarma == "+" ? " chosen" : "")
                        }
                        onClick={async () => {
                          const token = getCookie("token");
                          const userid = parseInt(getCookie("userid"));
                          const data = await window.TALKVIOAPI("changeKarma", {
                            userid,
                            token,
                            targetuserid: user.userid,
                            reduce: false,
                          });
                          window.displayError(data);
                          if (data.success) fetchData();
                        }}
                      ></FontAwesomeIcon>
                    </Tooltip>
                  ) : null}
                  <Tooltip
                    top={true}
                    hint={__(
                      "Karma is displaying how much other users approves or like this user or user actions. Only registred people with karma > 5 itself can change this value.",
                    )}
                  >
                    <span>{user.karma}</span>
                  </Tooltip>
                  {myUser && myUser.userid > 0 ? (
                    <Tooltip
                      top={true}
                      hint={__(
                        "Change your attitude to this user to negative. This will decrease user karma, you need 5 karma to perform this action.",
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faHeartCrack}
                        className={
                          "karmaButton down" +
                          (user?.mykarma == "-" ? " chosen" : "")
                        }
                        onClick={async () => {
                          const token = getCookie("token");
                          const userid = parseInt(getCookie("userid"));
                          const data = await window.TALKVIOAPI("changeKarma", {
                            userid,
                            token,
                            targetuserid: user.userid,
                            reduce: true,
                          });
                          window.displayError(data);
                          if (data.success) fetchData();
                        }}
                      ></FontAwesomeIcon>
                    </Tooltip>
                  ) : null}
                </div>
                <div className="desc">{__("Karma")}</div>
              </div>
              <div className="info-element subscribers">
                <div className="value">{user.subscribers} </div>
                <div className="desc">{__("Subscribers")}</div>
              </div>
              <div className="info-element">
                <div className="value">{user.ratings_up} </div>
                <div className="desc">{__("Upvoted")}</div>
              </div>
              <div className="info-element">
                <div className="value">{user.ratings_down} </div>
                <div className="desc">{__("Downvoted")}</div>
              </div>
              {myUser?.isAdmin || myUser?.userid == user.userid ? (
                <div className="info-element points">
                  <div className="value">{user.userpoints} </div>
                  <div className="desc">{__("Points")}</div>
                </div>
              ) : null}
              {myUser?.isAdmin || myUser?.userid == user.userid ? (
                <div className="info-element subscriptions">
                  <div className="value">{user.subscriptions} </div>
                  <div className="desc">{__("You subscribed")}</div>
                </div>
              ) : null}
            </div>
            <EditorInfo />
          </div>
          {myUser?.userid == user.userid && showSettings ? (
            <Settings
              user={myUser}
              onSuccess={(successData) => {
                if (successData?.changeNickName)
                  navigate(`/${successData.changeNickName}`);
                else fetchData();
              }}
            />
          ) : null}
        </div>
      ) : null}
      <RecentPosts
        source="user"
        noLimitation={true}
        recentPopularSwitcher={true}
        oldUnpopularSwitcher={true}
        fullTimePeriod={true}
        targetUserId={user?.userid || -1}
        leftRatingDisplay={true}
        customScrollData={{ showSettings, user }}
        origin="user"
        searchInput={true}
        onCustomScrollDataRestore={({ showSettings, user }) => {
          ignoreRequest.current = true;
          setUser(user);
          setShowSettings(showSettings);
        }}
      />
    </div>
  );
};
export default User;
