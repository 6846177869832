import React, { useContext, useState } from "react";
import { eraseCookie, getCookie, isMobile, setCookie } from "../global/Global";
import { imageData } from "../global/FileData";
import Checkbox from "./Checkbox";
import Input from "./Input";
import InputImage from "./InputImage";
import SelectBox from "./SelectBox";
import DefaultAvatar_150 from "../default-avatar_150.png";
import DefaultCustomBack from "../default-customback.avif";
import { onLoadSettings } from "../global/Hooks";
import md5 from "md5";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faPalette,
  faPaperPlane,
  faScrewdriverWrench,
  faKeyboard,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faEye } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "./Tooltip";
import KeysButtons from "../keybuttons.png";

const Splitter = ({ title, icon }) => {
  return (
    <div className="splitter">
      {icon}
      {title}
    </div>
  );
};

const Settings = ({
  user,
  onSuccess,
  avatarSettings = false,
  custombackSettings = false,
}) => {
  const [settingsToken, setSettingsToken] = useState(0);
  const [userNickName, setUserNickName] = useState(user?.username);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCofirm, setNewPasswordConfirm] = useState("");
  const [console, setConsole] = useState("");

  const [androidZoomText, setAndroidZoomText] = useState(
    typeof window.TalkvioAndroid != "undefined" &&
      window.TalkvioAndroid.getTextZoom &&
      window.TalkvioAndroid.getTextZoom(),
  );

  const setSettings = async (settings, successData) => {
    const token = getCookie("token");
    const userid = parseInt(getCookie("userid"));
    const data = await window.TALKVIOAPI("setSettings", {
      userid,
      token,
      settings,
    });
    if (data.success) {
      if (onSuccess) {
        onSuccess(successData);
        if (settings.username) {
          setUserNickName(null);
        }
      }
    }
  };

  useDidUpdateEffect(() => {
    if (newPassword.length < 8) return;
    if (newPasswordCofirm.length < 8) return;
    if (newPassword !== newPasswordCofirm) {
      return;
    }
    logT("password", "change password");
    setSettings({ password: md5(newPassword) });
    window.showOk(
      __("Password changed"),
      `${__("Your password has been changed")}. ${__("Your will be asked to relogin after few seconds.")}`,
    );
    eraseCookie("userid");
    eraseCookie("token");
    if (
      typeof window.TalkvioAndroid != "undefined" &&
      window.TalkvioAndroid.logOutPerformed
    ) {
      window.TalkvioAndroid.logOutPerformed();
    }
    setTimeout(() => {
      window.location.href = window.location.href;
    }, 6000);
  }, [newPassword, newPasswordCofirm]);

  return (
    <div
      className="settings"
      onFocus={() => {
        window.blockKeyTag("posts", "settings");
      }}
      onBlur={() => {
        window.unblockKeyTag("posts", "settings");
      }}
    >
      <div className="title">
        <FontAwesomeIcon icon={faGear} />
        &nbsp; {__("Settings")}
      </div>
      <Splitter
        icon={<FontAwesomeIcon icon={faEye} />}
        title={__("Profile appearance")}
      />
      <ul className="settingsList">
        {avatarSettings ? (
          <li className="select-box">
            <span className="placeholder">{__("Avatar")}: </span>
            <InputImage
              onFiles={async (files) => {
                const token = getCookie("token");
                const userid = parseInt(getCookie("userid"));
                const data = await window.TALKVIOAPI("setSettings", {
                  userid,
                  token,
                  settings: {
                    avatar: await imageData(files[0]),
                  },
                });
                window.displayError(data);
                if (data.success) {
                  if (onSuccess) onSuccess({ avatar: true });
                }
              }}
            >
              {user.avatarrevision > 0
                ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                : DefaultAvatar_150}
            </InputImage>
          </li>
        ) : null}
        {custombackSettings ? (
          <li className="select-box">
            <span className="placeholder">
              {__("Background of your page")}:{" "}
            </span>
            <InputImage
              width={300}
              onFiles={async (files) => {
                const token = getCookie("token");
                const userid = parseInt(getCookie("userid"));
                const data = await window.TALKVIOAPI("setSettings", {
                  userid,
                  token,
                  settings: {
                    customback: await imageData(files[0]),
                  },
                });
                window.displayError(data);
                if (data.success) {
                  setSettingsToken(settingsToken + 1);
                  if (onSuccess) onSuccess({ avatar: true });
                }
              }}
            >
              {user.customback
                ? `https://talkvio.com/customback/customback_${user.userid}.webp?${settingsToken}`
                : DefaultCustomBack}
            </InputImage>
          </li>
        ) : null}
        <li>
          <SelectBox
            value={user.gender}
            values={{
              none: __("none"),
              male: __("male"),
              female: __("female"),
            }}
            placeholder={__("Gender")}
            onChange={(value) => setSettings({ gender: value })}
          />
        </li>
        <li>
          <Input
            placeholderLeft={true}
            placeholder={__("Telegram profile")}
            value={user.telegram}
            onBlur={(value) => {
              setSettings({ telegram: value });
            }}
          />
        </li>
        <li>
          <Input
            placeholderLeft={true}
            placeholder={__("Homepage link")}
            value={user.homepage}
            onBlur={(value) => {
              setSettings({ homepage: value });
            }}
          />
        </li>
        <li>
          <Input
            placeholderLeft={true}
            placeholder={__("Discord profile")}
            value={user.discord}
            onBlur={(value) => {
              setSettings({ discord: value });
            }}
          />
        </li>
        {user.karma >= 3 && (
          <li>
            <Checkbox
              value={!!user?.showMySocials}
              onSwitch={(checked) => setSettings({ showMySocials: checked })}
              text={__("Show my socials under posts")}
            />
          </li>
        )}
        {user.karma >= 4 ? (
          <li>
            <Input
              placeholderLeft={true}
              placeholder={__("Status text")}
              value={user.usertitle}
              onBlur={(value) => {
                setSettings({ usertitle: value });
              }}
            />
          </li>
        ) : null}
        {user.canchangenick ? (
          <li>
            <Input
              placeholderLeft={true}
              placeholder={__("Username")}
              value={userNickName || ""}
              onChange={(value) => {
                setUserNickName(value);
              }}
            />
            <div
              className="clickButton"
              onClick={() => {
                window.confirmMessage(
                  __("Are you shure that you wanna change nick from") +
                    ` "${user.username}" ` +
                    __("into") +
                    ` "${userNickName}"? ` +
                    __("You can perform this action only once!"),
                  async () => {
                    setSettings(
                      { username: userNickName },
                      { changeNickName: userNickName },
                    );
                  },
                );
              }}
            >
              {__("Save changed username once!")}
            </div>
          </li>
        ) : null}
        <Splitter
          icon={<FontAwesomeIcon icon={faBell} />}
          title={__("Notifications")}
        />
        <li>
          <Checkbox
            value={
              typeof user.settings?.emailNotify == "undefined" ||
              user.settings?.emailNotify
            }
            onSwitch={(checked) => setSettings({ emailNotify: checked })}
            text={__("Send notifications over email")}
          />
        </li>
        {typeof window.TalkvioAndroid != "undefined" ? (
          <li>
            <Checkbox
              value={
                typeof user.settings?.androidNotifications == "undefined" ||
                user.settings?.androidNotifications
              }
              onSwitch={(checked) =>
                setSettings({ androidNotifications: checked })
              }
              text={__("Display notifications on Android system")}
            />
          </li>
        ) : null}
        {typeof window.TalkvioAndroid != "undefined" ? (
          <li>
            <Checkbox
              value={!!user.settings?.androidNotificationsSwipeNoClose}
              onSwitch={(checked) =>
                setSettings({ androidNotificationsSwipeNoClose: checked })
              }
              text={__("Dont mark as read on android notification swipe")}
            />
          </li>
        ) : null}
        <li>
          <Checkbox
            value={user.settings?.notifyIgnoreOtherUsersInMine}
            onSwitch={(checked) =>
              setSettings({ notifyIgnoreOtherUsersInMine: checked })
            }
            text={__(
              "Don't receive notification targeted to other users in your posts",
            )}
          />
        </li>
        <Splitter
          icon={<FontAwesomeIcon icon={faScrewdriverWrench} />}
          title={__("General")}
        />
        <li>
          <Input
            password={true}
            newPassword={true}
            placeholderLeft={true}
            placeholder={__("New password")}
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
          />
        </li>
        <li>
          <Input
            password={true}
            newPassword={true}
            placeholderLeft={true}
            placeholder={__("Confirm new password")}
            value={newPasswordCofirm}
            onChange={(value) => setNewPasswordConfirm(value)}
          />
        </li>
        {newPassword.length > 0 && newPasswordCofirm.length > 0 ? (
          <li>
            {__(
              "Be careful: after you will complete confirm password and passwords will be equal the password will be changed immediately and you will relogin to your account after few seconds.",
            )}
          </li>
        ) : null}
        <li>
          <Tooltip
            hint={__(
              "Special command line to input some special commands like for register on contests and so on",
            )}
          >
            <Input
              placeholderLeft={true}
              placeholder={__("Console")}
              value={console}
              onChange={(value) => setConsole(value)}
              onKeyPress={async (e) => {
                if (e.key === "Enter") {
                  if (console.toLowerCase() == "screenlogon") {
                    logT("console", "screen logs on");
                    window.screenLogs = true;
                    document.getElementById("lgses").style.display = "block";
                    setCookie("screenlog", true, 30);
                    setConsole("");
                    return;
                  } else if (console.toLowerCase() == "screenlogoff") {
                    logT("console", "screen logs off");
                    window.screenLogs = false;
                    document.getElementById("lgses").style.display = "none";
                    setCookie("screenlog", false, 30);
                    setConsole("");
                    return;
                  } else if (console.toLowerCase() == "networklogon") {
                    logT("console", "network logs on");
                    window.networkLogs = true;
                    document.getElementById("lgses").style.display = "block";
                    setConsole("");
                    return;
                  } else if (console.toLowerCase() == "networklogoff") {
                    logT("console", "network logs off");
                    window.networkLogs = false;
                    document.getElementById("lgses").style.display = "none";
                    setConsole("");
                    return;
                  }
                  e.preventDefault();
                  setConsole("");
                  if (console?.length > 0) {
                    const token = getCookie("token");
                    const userid = parseInt(getCookie("userid"));
                    const data = await window.TALKVIOAPI("console", {
                      userid,
                      token,
                      command: console,
                    });
                  }
                }
              }}
            />
          </Tooltip>
        </li>
        <Splitter
          icon={<FontAwesomeIcon icon={faPalette} />}
          title={__("Interface settings")}
        />
        <li>
          <Checkbox
            value={!!user.settings?.darkMode}
            onSwitch={(checked) => setSettings({ darkMode: checked })}
            text={__("Set dark theme as default")}
          />
        </li>
        <li>
          <Checkbox
            value={
              typeof user.settings?.showUserLinks == "undefined" ||
              user.settings?.showUserLinks
            }
            onSwitch={(checked) => setSettings({ showUserLinks: checked })}
            text={__("Show user socials under the post")}
          />
        </li>
        <li>
          <Checkbox
            value={
              typeof user.settings?.hints == "undefined" || user.settings?.hints
            }
            onSwitch={(checked) => setSettings({ hints: checked })}
            text={__("Enable hints")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.fixedTopHeader}
            onSwitch={(checked) => setSettings({ fixedTopHeader: checked })}
            text={__("Stick top panel at top to be always visible")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.topHeaderNoMouse}
            onSwitch={(checked) => setSettings({ topHeaderNoMouse: checked })}
            text={__("Don't open top panel on mouse nearby on desktop")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.videoOnScreenStart}
            onSwitch={(checked) => setSettings({ videoOnScreenStart: checked })}
            text={__("Autoplay video while it on screen")}
          />
        </li>
        <li>
          <Checkbox
            value={
              (typeof user.settings?.videoOutScreenStop == "undefined" &&
                isMobile()) ||
              user.settings?.videoOutScreenStop
            }
            onSwitch={(checked) => setSettings({ videoOutScreenStop: checked })}
            text={__("Stop video when it's out of the screen")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.scrollToTop}
            onSwitch={(checked) => setSettings({ scrollToTop: checked })}
            text={__("Display scroll to top button")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.visualLeftPanelHide}
            onSwitch={(checked) =>
              setSettings({ visualLeftPanelHide: checked })
            }
            text={__("Hide left panel from desktop view")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.visualRightPanelHide}
            onSwitch={(checked) =>
              setSettings({ visualRightPanelHide: checked })
            }
            text={__("Hide right panel from desktop view")}
          />
        </li>
        <li>
          <Checkbox
            value={user.settings?.editorFilesSortStable}
            onSwitch={(checked) =>
              setSettings({ editorFilesSortStable: checked })
            }
            text={__(
              "Ignore system settings and always sort multiple files by filename in editor (from A to Z)",
            )}
          />
        </li>
        <Splitter
          icon={<FontAwesomeIcon icon={faPaperPlane} />}
          title={__("Content settings")}
        />
        <li>
          <Checkbox
            value={
              typeof user.settings?.showAdult == "undefined" ||
              user.settings?.showAdult
            }
            onSwitch={(checked) => setSettings({ showAdult: checked })}
            text={__("Show adult / NSFW content")}
          />
        </li>
        <li>
          <Checkbox
            value={!!user.settings?.hideViewHistory}
            onSwitch={(checked) => setSettings({ hideViewHistory: checked })}
            text={__("Hide posts from the feed that have been watched")}
          />
        </li>
        {user.settings?.hideViewHistory ? (
          <li>
            <Checkbox
              value={!!user.settings?.hideViewHistoryCollapse}
              onSwitch={(checked) =>
                setSettings({ hideViewHistoryCollapse: checked })
              }
              text={__("Collapse posts instread of hide them")}
            />
          </li>
        ) : null}
        {user.settings?.hideViewHistory ? (
          <li>
            <Checkbox
              value={!!user.settings?.hideViewHistoryAllPages}
              onSwitch={(checked) =>
                setSettings({ hideViewHistoryAllPages: checked })
              }
              text={__("Hide posts for all pages not only the smart feed")}
            />
          </li>
        ) : null}
        <li>
          <Checkbox
            value={!!user.settings?.viewWhenLongLook}
            onSwitch={(checked) => setSettings({ viewWhenLongLook: checked })}
            text={__("Mark post viewed when long reading them")}
          />
        </li>
        {user.settings?.viewWhenLongLook && user.settings?.hideViewHistory ? (
          <li>
            <Checkbox
              value={!!user.settings?.viewWhenShortLook}
              onSwitch={(checked) =>
                setSettings({ viewWhenShortLook: checked })
              }
              text={__(
                "Mark post viewed when reading them really short or pass them",
              )}
            />
          </li>
        ) : null}
        <li>
          <Checkbox
            value={!!user.settings?.feedReplies}
            onSwitch={(checked) => setSettings({ feedReplies: checked })}
            text={__("Show reply forms after each post in feed")}
          />
        </li>
        <li>
          <Checkbox
            value={!!user.settings?.topRatingDisplay}
            onSwitch={(checked) => setSettings({ topRatingDisplay: checked })}
            text={__("Display rating changer inside header of post or comment")}
          />
        </li>
        <li>
          <Checkbox
            value={!!user.settings?.noDelayedPosts}
            onSwitch={(checked) => setSettings({ noDelayedPosts: checked })}
            text={__(
              "Hide delayed posts from main feed, display only in delayed page",
            )}
          />
        </li>
        {typeof window.TalkvioAndroid != "undefined" &&
        window.TalkvioAndroid.setTextZoom ? (
          <li>
            <Input
              style={{ maxWidth: 75 }}
              leftPanel={
                <button
                  onClick={() => {
                    if (androidZoomText <= 50) return;
                    window.TalkvioAndroid.setTextZoom(androidZoomText - 10);
                    setAndroidZoomText(androidZoomText - 10);
                  }}
                >
                  -
                </button>
              }
              rightPanel={
                <button
                  onClick={() => {
                    if (androidZoomText >= 150) return;
                    window.TalkvioAndroid.setTextZoom(androidZoomText + 10);
                    setAndroidZoomText(androidZoomText + 10);
                  }}
                >
                  +
                </button>
              }
              placeholderLeft={true}
              placeholder={__("Application text zoom")}
              value={androidZoomText + "%"}
              onChange={(value) => {}}
              onBlur={(value) => {
                let intValue = parseInt(value);
                if (isNaN(intValue)) intValue = 100;
                intValue = Math.min(intValue, 150);
                intValue = Math.max(intValue, 50);
                setAndroidZoomText(intValue);
                window.TalkvioAndroid.setTextZoom(intValue);
              }}
            />
          </li>
        ) : null}
      </ul>
      <Splitter
        icon={<FontAwesomeIcon icon={faKeyboard} />}
        title={__("Controls")}
      />
      <div className="keyboardHelp">
        <div className="flex column center-column keyboardStruct">
          <img src={KeysButtons} alt="keyboard button" />
        </div>
        <div className="keyboardDesc typicalBlock">
          <div>
            <krb>D</krb> - {__("Scroll to next post")}
          </div>
          <div>
            <krb>A</krb> - {__("Scroll to previous post")}
          </div>
          <div>
            <krb>W</krb> - {__("Like this post")}
          </div>
          <div>
            <krb>S</krb> - {__("Dislike current post")}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;
